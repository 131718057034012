import React from "react";
import { Grid, Typography, Box } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CheckText from "../../../components/common/checkText";
import BlueButton from "../../../components/common/blueButton";
import SurprisedWoman from "../../../assets/surprised-woman.png";

const useStyles = makeStyles((theme) => ({
  appIntro: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(3, 2),
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      justifyContent: "space-between",
      padding: theme.spacing(3, 5),
    },
  },
  appIntroInfoTextBox: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(4),
    alignItems: "flex-start",
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up("md")]: {
      alignItems: "flex-start",
      width: "35%",
      gap: theme.spacing(6),
      marginBottom: 0,
    },
  },
  appIntroInfosText: {
    fontFamily: "Nunito",
    fontWeight: 700,
    color: "#39adc4",
    textAlign: "center",
    fontSize: 24,
    padding: theme.spacing(2, 6),
    lineHeight: "34px",
    [theme.breakpoints.up("md")]: {
      fontSize: 42,
      padding: theme.spacing(2, 0),
      textAlign: "start",
    },
  },
  checksBox: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      gap: theme.spacing(2.5),
    },
  },
  appIntroImage: {
    maxWidth: "100%",
    [theme.breakpoints.up("md")]: {
      maxWidth: "100%",
    },
  },
  blueButton: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

const ClientEaseSection = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const checkTextData = [
    {
      text: "Não precisa baixar app nenhum, é só acessar seu link",
      effect: "fade-left",
      delay: "600"
    },
    { text: "Lembretes para não se esquecer o horário", effect: "fade-left", delay: "700" },
    {
      text: "Autonomia para ele conseguir agendar sem precisar falar com você",
      effect: "fade-left",
      delay: "800"
    },
  ];

  return (
    <Grid container className={classes.appIntro}>
      {!isDesktop && (
        <Typography variant="h5" className={classes.appIntroInfosText}>
          Mais facilidade para o seu cliente!
        </Typography>
      )}
      {isDesktop && (
        <Grid item>
          <Box
            component="img"
            src={SurprisedWoman}
            alt="Mulher surpresa olhando para o celular"
            className={classes.appIntroImage}
            data-aos={isMobile ? "" : "fade-in"}
            data-aos-delay="900"
            data-aos-duration="600"

          />
        </Grid>
      )}
      <Grid item className={classes.appIntroInfoTextBox}>
        {isDesktop && (
          <Typography
            variant="h2"
            className={classes.appIntroInfosText}
            data-aos={isMobile ? "" : "fade-in"}
            data-aos-duration="800"
          >
            Mais facilidade para o seu cliente!
          </Typography>
        )}
        <Grid container className={classes.checksBox}>
          {checkTextData.map((item, index) => (
            <CheckText
              key={index}
              text={item.text}
              effect={item.effect}
              delay={item.delay}
            />
          ))}
        </Grid>
        <BlueButton
          text="Comece agora"
          className={classes.blueButton}
          delay={'1000'}
        />
      </Grid>
      {!isDesktop && (
        <Grid item>
          <Box
            component="img"
            src={SurprisedWoman}
            alt="Mulher surpresa olhando para o celular"
            className={classes.appIntroImage}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default ClientEaseSection;
