import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import BackgroundColumn from '../../../assets/background-column.png';

const useStyles = makeStyles((theme) => ({
  infoBoxWrapper: {
    borderRadius: 22,
    boxShadow: '0px 1px 23px 0px rgba(0, 0, 0, 0.17)',
    border: 'none',
    width: '100%',
    display: 'flex',
    cursor: 'pointer',
  },
  infoBoxWrapperClosed: {
    borderRadius: 22,
    boxShadow: '0px 1px 23px 0px rgba(0, 0, 0, 0.17)',
    border: 'none',
    width: '100%',
    display: 'flex',
    marginBottom: 0,
    cursor: 'pointer',
  },
  colorColumn: {
    width: 30,
    backgroundImage: `url(${BackgroundColumn})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    borderRadius: '0px 0px 0px 30px',
  },
  infoBoxInfosText: {
    color: '#7b7f87',
    fontSize: 18,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
  },
  infoBoxInfos: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: theme.spacing(2.5, 3),
  },
  infoBoxInfosTitle: {
    color: '#4e5258',
    fontFamily: 'Nunito',
    fontSize: 20,
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 'normal',
    alignItems: 'center',
    wordWrap: 'break-word',
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  titleWrapperClosed: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 0,
  },
  titleWrapperImg: {
    marginRight: theme.spacing(2),
  },
}));

function InfoBox(props) {
  const { icon, title, text, activeBox, index, handleClick, handleImage } = props;
  const isActive = activeBox === index;
  const classes = useStyles();

  if (isActive) handleImage(index);

  return (
    <Box
      className={isActive ? classes.infoBoxWrapper : classes.infoBoxWrapperClosed}
      onClick={handleClick}
      component="div"
    >
      {isActive && <Box className={classes.colorColumn} component="div"></Box>}
      <Box className={classes.infoBoxInfos} component="div">
        <Box className={isActive ? classes.titleWrapper : classes.titleWrapperClosed} component="div">
          <img src={icon} alt="icon" className={classes.titleWrapperImg}/>
          <Typography variant="h3" className={classes.infoBoxInfosTitle}>
            {title}
          </Typography>
        </Box>
        {isActive && (
          <Typography variant="body1" className={classes.infoBoxInfosText}>
            {text}
          </Typography>
        )}
      </Box>
    </Box>
  );
}

export default InfoBox;