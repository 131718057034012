import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from "@mui/material";
import { useMediaQuery } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import GreenCheck from '../../../assets/icons/icon-check.png';


const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  text: {
    color: '#7B7F87',
    fontFamily: 'Nunito',
    fontSize: 18,
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
  },
  image: {
    marginRight: 10,
  },
});

function CheckText(props) {
  const { text, effect, delay } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box className={classes.root} data-aos={isMobile ? "" : {effect}} data-aos-delay={delay}  data-aos-duration="800">
      <img className={classes.image} src={GreenCheck} alt='green checks'/>
      <Typography className={classes.text}>{text}</Typography>
    </Box>
  );
}

export default CheckText;