import React from 'react';
import { Grid, Typography, Box, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from "@mui/material";
import IconPlay from '../../../assets/icons/icon-play.png';
import VideoImage from '../../../assets/video-background.png';
import VideoImagePhone from '../../../assets/background-phone.png';

const useStyles = makeStyles((theme) => ({
    appVideo: {
        width: "100%",
        height: "50%",
        backgroundImage: `url(${VideoImage})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.down('sm')]: {
            height: "50%",
            backgroundImage: `url(${VideoImagePhone})`,
            backgroundSize: "cover",
            padding: theme.spacing(2),
        },
    },
    appVideoSpan: {
        fontSize: 38,
        lineHeight: "60px",
        color: "#fff",
        fontWeight: 700,
        fontFamily: "Nunito",
        [theme.breakpoints.down('sm')]: {
            fontSize: 24,
            lineHeight: "34px",
            justifyContent: 'center',
            width: '90%',
            textAlign: 'center',
        },
    },
    largePlayButton: {
        cursor: "pointer",
        transition: "all 0.3s linear",
        "&:hover": {
            transform: "scale(1.1)",
        },
        maxWidth: "100%",
        height: "auto",
        width: "auto",
        [theme.breakpoints.down('sm')]: {
            width: "100px",
            height: "100px",
        },
    },
}));

const VideoSection = () => {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <Grid container className={classes.appVideo}  data-aos={isMobile ? "" : "fade-zoom-in"}
        data-aos-easing="ease-in-back"
        data-aos-delay="600"
        data-aos-offset="0">
            <Typography
                variant="body1"
                className={classes.appVideoSpan}
            >
                Veja tudo o que o Prit pode fazer por você e pelo seu negócio!
            </Typography>
            <Box 
                onClick={() => {window.open('https://www.youtube.com/watch?v=HboH4MNfuOg')}}
                component="img"
                src={IconPlay}
                alt="play-button"
                className={classes.largePlayButton}
            />
        </Grid>
    );
};

export default VideoSection;
