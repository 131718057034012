import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import VideoBox from "../../../components/common/videoBox";
import CarouselComponent from "../../../components/common/carouselVideos";

const useStyles = makeStyles((theme) => ({
  videoSection: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
    overflow: "visible",
    [theme.breakpoints.up("md")]: {},
  },
  videoSectionBoxes: {
    [theme.breakpoints.up("md")]: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
      alignItems: "center",
      width: "100%",
      margin: "0 auto",
    },
    [theme.breakpoints.down("md")]: {
      gap: 40,
      justifyContent: "center",
    },
  },
  appInfosTitle: {
    fontSize: 24,
    [theme.breakpoints.up("md")]: {
      fontSize: 40,
    },
    textAlign: "center",
    color: "#39adc4",
    fontFamily: "Nunito",
    fontWeight: 700,
    [theme.breakpoints.up("xs")]: {
      marginBottom: theme.spacing(5),
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.spacing(0),
      textAlign: 'center',
    },
  },
  purple: {
    color: "#201D4D",
  },
  appInfosBoxes: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "none",
    overflow: "visible",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
  },
}));

const ProfessionalsVideoSection = ({ InfoVideosData }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <Grid container className={classes.videoSection}>
      <Typography
        variant="h2"
        className={`${classes.appInfosTitle} ${classes.purple}`}
        data-aos={isMobile ? "" : "zoom-out-down"}
        data-aos-duration="800"
      >
        Feito para profissionais que trabalham com hora marcada
      </Typography>
      {isMobile ? (
        <Grid container className={classes.appInfosBoxes}>
          <CarouselComponent carouselData={InfoVideosData} />
        </Grid>
      ) : (
        <Grid container className={classes.videoSectionBoxes}>
          {InfoVideosData.map((data) => (
            <VideoBox
              key={data.key}
              background={data.background}
              text={data.text}
              url={data.url}
            />
          ))}
        </Grid>
      )}
    </Grid>
  );
};

export default ProfessionalsVideoSection;
