import React from "react";
import { Swiper, SwiperSlide} from "swiper/react";
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import Swipero from 'swiper';
import { makeStyles } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@mui/material";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Icon from "../../../assets/icons/icon-play-small.png";
import "swiper/swiper-bundle.css";
import './swiperCustomStyles.css';

const useStyles = makeStyles((theme) => ({
  infoBoxInfosTitle: {
    color: "#4e5258",
    fontFamily: "Nunito",
    fontSize: 18,
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
    alignItems: "center",
    wordWrap: "break-word",
  },
  titleWrapper: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  spacing: {
    margin: theme.spacing(0, 1.5),
  },
  titleWrapperImg: {
    marginRight: theme.spacing(1),
    width: "16px",
    height: "16px",
  },
  videoBoxWrapper: {
    width: "100%",
    marginBottom: 20,
  },
  backgroundVideo: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "auto",
    backgroundPosition: "center",
    width: "16em",
    height: "13.6em",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: 'column',
    alignItems: "center",
    borderRadius: "40px 40px 40px 40px",
    margin: "0 auto",
    boxShadow: "0px 1px 33px 0px rgba(0, 0, 0, 0.17)",
    marginTop: 31,
    marginBottom: 31,
  },
  videoBoxInfos: {
    width: "100%",
    background: "#39ADC4",
    borderRadius: "0px 0px 40px 40px",
  },
  videoName: {
    fontSize: 18,
    textAlign: "center",
    color: "#fff",
    fontFamily: "Nunito",
    fontStyle: "normal",
    fontWeight: 500,
    padding: theme.spacing(1),
  },
  slideItem: {
    padding: theme.spacing(0, 1),
  },
  playButton:{
    marginTop: 40,
  },
}));

const CarouselVideos = (carouselData) => {
  Swipero.use([Navigation, Pagination, Autoplay]);
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  
  const settings = {
    modules: [Autoplay, Pagination, Navigation],
    spaceBetween: 30,
    slidesPerView: 1,
    loop: true,
    pagination: {
      clickable: true,
      dynamicBullets: true,
    },
    autoplay:{
      delay: 4000,
    }
  };

  return (
    <Box
      className={classes.videoBoxWrapper}
      component="div"
      data-aos={isMobile ? "" : "zoom-out"}
      data-aos-delay="300"
      data-aos-duration="1000"
    >
      <Swiper {...settings} >
        {carouselData.carouselData.map((item, index) => (
          <SwiperSlide key={index}>
            <Box
              className={classes.backgroundVideo}
              style={{ backgroundImage: `url(${item.background})` }}
              onClick={() => {window.open(item.url)}}
            >
              <img src={Icon} alt="icon" className={classes.playButton}/>

              <Box className={classes.videoBoxInfos}>
                <Typography variant="h6" className={classes.videoName}>
                  {item.text ? item.text : "Beleza"}
                </Typography>
              </Box>
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};

export default CarouselVideos;
