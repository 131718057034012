import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { EffectCreative } from 'swiper/modules';
import ArrowLeftCarousel from "../../../assets/icons/arrowLeft-carousel.png";
import ArrowRightCarousel from "../../../assets/icons/arrowRight-carousel.png";
import "swiper/css/bundle";

const useStyles = makeStyles((theme) => ({
  infoBoxInfosText: {
    color: "#7b7f87",
    fontSize: 15,
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  },
  infoBoxInfos: {
    width: "100%",
    height: "195px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: theme.spacing(2, 2),
    boxShadow: "0px 1px 10px 0px rgba(0, 0, 0, 0.17)",
    borderRadius: 22,
    marginTop: 10,
    marginBottom: 50,
    backgroundColor: "white",
  },
  infoBoxInfosTitle: {
    color: "#4e5258",
    fontFamily: "Nunito",
    fontSize: 18,
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
    alignItems: "center",
    wordWrap: "break-word",
  },
  titleWrapper: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  spacing: {
    paddingLeft: theme.spacing(1.5),
  },
  titleWrapperImg: {
    marginRight: theme.spacing(1),
    width: "16px",
    height: "16px",
  },
  arrowButton: {
    position: "absolute",
    zIndex: 5,
  },
  nextArrow: {
    right: 25,
  },
  prevArrow: {
    left: 25,
  },
}));

const CarouselComponent = (props) => {
  const classes = useStyles();
  const {carouselData, onSlideChange} = props;
  const [currentSlide, setCurrentSlide] = useState(0);
  const [leftArrowOpacity ,setLeftArrowOpacity] = useState(true);
  const [rightArrowOpacity ,setRightArrowOpacity] = useState(true);
  const sliderRef = useRef(null);

  const handleNext = () => {
    if (currentSlide < 4) {
      setCurrentSlide(currentSlide + 1);
    }
  };

  const handlePrevious = () => {
    if (currentSlide > 0) {
      setCurrentSlide(currentSlide - 1);
    }
  };

  const handleSlideChange = (swiper) => {
    setCurrentSlide(swiper.realIndex);
    onSlideChange(swiper.realIndex);
  };

  const updateArrowsOpacity = () => {
    if (sliderRef.current && sliderRef.current.swiper) {
      const swiper = sliderRef.current.swiper;
      setLeftArrowOpacity(swiper.isBeginning ? false : true);
      setRightArrowOpacity(swiper.isEnd ? false : true);
    }
  };

  
  useEffect(() => {
    sliderRef.current.swiper.slideTo(currentSlide, 500, false);
    updateArrowsOpacity();
  }, [currentSlide]);

  const settings = {
    spaceBetween: 40, 
    slidesPerView: 1, 
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    pagination: {
      clickable: true,
      dynamicBullets: true,
    },
    onSlideChange: handleSlideChange, 
    effect:'creative',
    creativeEffect:{
      prev: {
        translate: [0, 0, -400],
      },
      next: {
        translate: ['100%', 0, 0],
      },
    },
    modules:[EffectCreative],
  };

  return (
    <Grid
      style={{
        width: "100%",
        justifyContent: "center",
        display: "flex",
        alignItems: "center",
        margin: "0 autos",
      }}
    >
      {leftArrowOpacity  &&
      <Box
        component="img"
        src={ArrowLeftCarousel}
        className={`${classes.arrowButton} ${classes.prevArrow}`}
        onClick={handlePrevious}
      />}
      <Swiper
        {...settings}
        style={{ padding: "0px 5px 0 5px" }}
        ref={sliderRef}
      >
        {carouselData.map((item, index) => (
          <SwiperSlide key={index} style={{ padding: "0px 40px 0 40px" }}>
            <Box className={classes.infoBoxInfos}>
              <Box className={classes.titleWrapper}>
                <img
                  src={item.icon}
                  alt="icon"
                  className={classes.titleWrapperImg}
                />
                <Typography variant="h6" className={classes.infoBoxInfosTitle}>
                  {item.title}
                </Typography>
              </Box>
              <Typography variant="body1" className={classes.infoBoxInfosText}>
                {item.text}
              </Typography>
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>
      {rightArrowOpacity && <Box
        component="img"
        src={ArrowRightCarousel}
        className={`${classes.arrowButton} ${classes.nextArrow}`}
        onClick={handleNext}
      />}
    </Grid>
  );
};

export default CarouselComponent;
