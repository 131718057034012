import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import mixpanel from "mixpanel-browser";
import ReactPixel from "react-facebook-pixel";
import {
  Box,
  Typography,
  IconButton,
  Drawer,
  List,
  ListItem,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MenuIcon from "@mui/icons-material/Menu";
import { useTheme } from "@mui/material";
import PritLogo from "../../../assets/prit-logo.png";
import BlueButton from "../blueButton";

const useStyles = makeStyles((theme) => ({
  headerBox: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    boxShadow: "0px 4px 5px 0px rgba(0, 0, 0, 0.10)",
    padding: theme.spacing(2, 5),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1, 2),
    },
  },
  headerLinks: {
    color: "#39ADC4",
    fontSize: "20px",
    cursor: "pointer",
    textDecoration: "none",
    whiteSpace: "nowrap",
    marginRight: "20px",
    "&:hover": {
      opacity: 0.7,
      transition: "opacity 0.3s ease-in-out",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  headerLinksList: {
    color: "#39ADC4",
    fontSize: "20px",
    cursor: "pointer",
    "&:hover": {
      opacity: 0.7,
      transition: "opacity 0.3s ease-in-out",
    },
  },
  pritLogoImg: {
    cursor: "pointer",
    maxWidth: "100%",
    height: "auto",
    width: "auto",
    "&:hover": {
      opacity: 0.7,
      transition: "opacity 0.3s ease-in-out",
    },
  },
  headerButtons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    width: "75%",
    [theme.breakpoints.down("sm")]: {
      width: "auto",
    },
  },
  menuButton: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    marginLeft: theme.spacing(1),
  },
  list: {
    width: 200,
  },
  drawerContent: {
    width: "auto",
    "&:hover": {
      opacity: 0.9,
      transition: "opacity 0.3s ease-in-out",
    },
  },
}));

function PageHeader({ featuresSectionRef, casesSectionRef, pricingSectionRef }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [mobileOpen, setMobileOpen] = useState(false);

  useEffect(() => {
    const { pathname } = location;
    if (pathname === "/features" && featuresSectionRef.current) {
      featuresSectionRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (pathname === "/cases" && casesSectionRef.current) {
      casesSectionRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (pathname === "/pricing" && pricingSectionRef.current) {
      pricingSectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [location, featuresSectionRef, casesSectionRef, pricingSectionRef]);

  const handleFaqClick = () => {
    window.open("https://pritbusiness.zendesk.com/hc/pt-br");
    ReactPixel.track("site - acessou perguntas frequentes");
    mixpanel.track("site - acessou perguntas frequentes");
  };

  const toggleDrawer = (open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setMobileOpen(open);
  };

  const list = () => (
    <div
      className={classes.drawerContent}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <ListItem onClick={() => navigate("/features")}>
          <Typography className={classes.headerLinksList}>Funcionalidades</Typography>
        </ListItem>
        <ListItem onClick={() => navigate("/cases")}>
          <Typography className={classes.headerLinksList}>Depoimentos</Typography>
        </ListItem>
        <ListItem onClick={() => navigate("/pricing")}>
          <Typography className={classes.headerLinksList}>Preço</Typography>
        </ListItem>
        <ListItem onClick={handleFaqClick}>
          <Typography className={classes.headerLinksList}>Perguntas Frequentes</Typography>
        </ListItem>
      </List>
    </div>
  );

  return (
    <Box className={classes.headerBox}>
      <img
        src={PritLogo}
        alt="prit logo"
        className={classes.pritLogoImg}
        onClick={() => navigate("/")}
      />
      <Box className={classes.headerButtons}>
        <Typography
          className={classes.headerLinks}
          onClick={() => navigate("/features")}
          data-aos={isMobile ? "" : "fade-in"}
          data-aos-delay="300"
        >
          Funcionalidades
        </Typography>
        <Typography
          className={classes.headerLinks}
          onClick={() => navigate("/cases")}
          data-aos={isMobile ? "" : "fade-in"}
          data-aos-delay="400"
        >
          Depoimentos
        </Typography>
        <Typography
          className={classes.headerLinks}
          onClick={() => navigate("/pricing")}
          data-aos={isMobile ? "" : "fade-in"}
          data-aos-delay="500"
        >
          Preço
        </Typography>
        <Typography
          className={classes.headerLinks}
          onClick={handleFaqClick}
          data-aos={isMobile ? "" : "fade-in"}
          data-aos-delay="600"
        >
          Perguntas Frequentes
        </Typography>
        <BlueButton text="Teste agora" delay={"700"} />
        <IconButton
          edge="start"
          className={classes.menuButton}
          onClick={toggleDrawer(true)}
        >
          <MenuIcon />
        </IconButton>
        <Drawer anchor="right" open={mobileOpen} onClose={toggleDrawer(false)}>
          {list()}
        </Drawer>
      </Box>
    </Box>
  );
}

export default PageHeader;
