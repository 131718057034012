import React, { useState, useRef } from "react";
import { Box, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import IconClock from "../../assets/icons/icon-clock.png";
import IconCoin from "../../assets/icons/icon-coin.png";
import IconGraphs from "../../assets/icons/icon-graphs.png";
import IconBell from "../../assets/icons/icon-bell.png";
import BackSaude from "../../assets/saude-video-back.png";
import BackEsporte from "../../assets/esporte-video-back.png";
import BackPet from "../../assets/pet-video-back.png";
import BackBeleza from "../../assets/beleza-video-back.png";
import IntroSection from "./introSection";
import VideoSection from "./videoSection";
import ClientEaseSection from "./clientEaseSection";
import ProfessionalsVideoSection from "./professionalsVideosSection";
import InfoSection from "./infoSection";
import SupportSection from "./supportSection";
import PricingSection from "./pricingSection";
import FooterSection from "./footerSection";
import PageHeader from "../../components/common/pageHeader";
import FeedBackSection from "./feedbackSection";

import "./index.css";

const InfoBoxData = [
  {
    key: "1",
    icon: IconClock,
    text: "Crie um link único da sua página para colocar nas suas redes sociais e fidelize seus clientes deixando que eles agendem online com você 24h por dia, 7 dias por semana.",
    title: "Agendamento Online",
    delay: "600",
  },
  {
    key: "2",
    icon: IconCoin,
    text: "Com o Prit Pay você solicita o pagamento parcial ou total para confirmação do agendamento e para de perder dinheiro com clientes que cancelam de última hora.",
    title: "Cobrança Antecipada",
    delay: "700",
  },
  {
    key: "3",
    icon: IconBell,
    text: "Deixe que o Prit lembre os seus clientes da reserva com o envio dos lembretes automáticos e seja avisado sobre os novos agendamentos e pagamentos dos seus clientes.",
    title: "Lembretes automáticos",
    delay: "800",
  },
  {
    key: "4",
    icon: IconGraphs,
    text: "Tenha o controle de todo o seu histórico de vendas e facilite a forma que você vê seu lucro diário, semanal e mensal.",
    title: "Controle Financeiro",
    delay: "900",
  },
];

const InfoVideosData = [
  {
    key: "1",
    background: BackBeleza,
    text: "Beleza",
    url: "https://www.youtube.com/watch?v=vpkXf65XbYg",
  },
  {
    key: "2",
    background: BackEsporte,
    text: "Esporte",
    url: "https://www.youtube.com/watch?v=Ede4olIqRtg&feature=youtu.be",
  },
  {
    key: "3",
    background: BackSaude,
    text: "Saúde",
    url: "https://www.youtube.com/shorts/hThOhZoKcII",
  },
  {
    key: "4",
    background: BackPet,
    text: "Pet",
    url: "https://www.youtube.com/watch?v=7LGy8mlzREg&feature=youtu.be",
  },
];

const useStyles = makeStyles((theme) => ({
  homePage: {
    width: "100%",
    height: "100vh",
    padding: 0,
  },
  spacingBox: {
    paddingLeft: theme.spacing(5),
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
    },
  },
  division: {
    width: "100%",
    background: "#7B7F87",
  },
}));

function Home() {
  const classes = useStyles();

  const infoRef = useRef(null);
  const feedbackRef = useRef(null);
  const pricingRef = useRef(null);

  const [activeBox, setActiveBox] = useState(0);

  const handleToggleOpen = (index) => {
    setActiveBox(activeBox === index ? null : index);
  };

  return (
    <Container maxWidth={false} className={classes.homePage}>
      <PageHeader
        featuresSectionRef={infoRef}
        casesSectionRef={feedbackRef}
        pricingSectionRef={pricingRef}
      />
      <IntroSection/>
      <VideoSection/>
      <div ref={infoRef}>
        <InfoSection
          InfoBoxData={InfoBoxData}
          handleToggleOpen={handleToggleOpen}
          activeBox={activeBox}
        />
      </div>
      <ClientEaseSection />
      <div ref={feedbackRef}>
        <ProfessionalsVideoSection InfoVideosData={InfoVideosData} />
        <Box className={classes.spacingBox}>
          <FeedBackSection />
        </Box>
      </div>
      <SupportSection />
      <PricingSection />
      <div ref={pricingRef}/>
      <FooterSection />
    </Container>
  );
}

export default Home;
